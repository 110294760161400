import { Component, NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-esign-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class ESignFooterComponent {
  year: number = new Date().getFullYear();
  version = '3.0.0.4';
}

@NgModule({
  imports: [TranslateModule],
  exports: [ESignFooterComponent],
  declarations: [ESignFooterComponent],
})
export class ESignFooterModule {
  constructor(private translate: TranslateService) {}
}
